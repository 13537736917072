import { action, persist } from 'easy-peasy';
var DEFAULT_SEARCH_QUERY = {
  artistName: {
    contains: ''
  }
};
var DEFAULT_SORT_QUERY = {};
var artistsModel = {
  storedSearchQuery: DEFAULT_SEARCH_QUERY,
  storedCurrentPage: 1,
  setStoredSearchQuery: action(function (state, payload) {
    state.storedSearchQuery = payload;
  }),
  setStoredCurrentPage: action(function (state, payload) {
    state.storedCurrentPage = payload;
  })
};
var artistsSortModel = {
  storedSortQuery: DEFAULT_SORT_QUERY,
  setStoredSortQuery: action(function (state, payload) {
    state.storedSortQuery = payload;
  })
};
var artistsStore = {
  artists: persist(artistsModel, {
    storage: 'sessionStorage'
  }),
  artistsSort: persist(artistsSortModel, {
    storage: 'localStorage'
  })
};
export { artistsStore };