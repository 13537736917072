import { authenticatedAxiosStore, authStore } from './auth';
import { collectionCarouselStore } from './collection-carousel';
import { etherStore } from './ether';
import { locationStore } from './location';
import { logrocketStore } from './logrocket';
import { notificationsStore } from './notifications';
import { pageSettingsStore } from './page-settings';
import { scrollStore } from './scroll-store';
import { volumeStore } from './volume';
var commonStore = Object.assign({}, authStore, authenticatedAxiosStore, scrollStore, notificationsStore, pageSettingsStore, locationStore, volumeStore, etherStore, logrocketStore, collectionCarouselStore);
export { commonStore };