import _forEach from "lodash-es/forEach";

var _CONFIG;

import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import fetch from 'isomorphic-fetch';
import { extractGraphQLErrorContext, logger } from '@/common/utils/logger';

var fetcher = function fetcher() {
  var _window;

  if (typeof window === 'undefined' || !window.fetch) {
    return fetch.apply(void 0, arguments);
  }

  return (_window = window).fetch.apply(_window, arguments);
};

var client = new ApolloClient({
  link: ApolloLink.from([onError(function (_ref) {
    var graphQLErrors = _ref.graphQLErrors,
        networkError = _ref.networkError,
        operation = _ref.operation;

    if (graphQLErrors) {
      _forEach(graphQLErrors, function (error) {
        logger.exception(error, "[GraphQL error]: Message: " + error.message + ", Operation: " + operation.operationName + ", Path: " + error.path, extractGraphQLErrorContext(error));
      });
    }

    if (networkError) {
      logger.warn("[Network error]: " + networkError, {
        networkError: networkError
      });
    }
  }), new HttpLink({
    uri: CONFIG.endpoints.moonaApi,
    credentials: 'same-origin',
    fetch: fetcher
  })]),
  cache: new InMemoryCache(),
  connectToDevTools: (_CONFIG = CONFIG) === null || _CONFIG === void 0 ? void 0 : _CONFIG.devFeatures.apolloDevTools
});
export { client };