import * as React from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import { getLibrary } from '@/common/providers/web3/library';
import { jsx as ___EmotionJSX } from "@emotion/core";

var wrapRootElementWeb3Provider = function wrapRootElementWeb3Provider(_ref) {
  var element = _ref.element;
  return ___EmotionJSX(Web3ReactProvider, {
    getLibrary: getLibrary
  }, element);
};

wrapRootElementWeb3Provider.displayName = "wrapRootElementWeb3Provider";
export { wrapRootElementWeb3Provider };