import _startsWith from "lodash-es/startsWith";
import _set from "lodash-es/set";
import _get from "lodash-es/get";

var _CONFIG;

import { createStore } from 'easy-peasy';
import LogRocket from 'logrocket';
import { artistsStore } from '@/artist/store';
import { withdrawTokenStore } from '@/artwork/components/artwork-collections/withdraw-token/store';
import { artworksStore } from '@/artwork/store';
import { checkoutStore } from '@/checkout/store';
import { commonStore } from '@/common/store';
import { logger } from '@/common/utils/logger';
import { exhibitionsStore } from '@/exhibition/store';
import { postsStore } from '@/post/store';
import { profileStore } from '@/profile/store';
var rootStoreModel = Object.assign({}, commonStore, profileStore, checkoutStore, withdrawTokenStore, artworksStore, artistsStore, postsStore, exhibitionsStore);
var middleware = [];

if (CONFIG.features.logrocket) {
  middleware.push(LogRocket.reduxMiddleware({
    actionSanitizer: function actionSanitizer(action) {
      if (_startsWith(action.key, '@action.scroll')) {
        return null;
      }

      return action;
    }
  }));
}

var storeName = 'app-root-store';
var rootStore = createStore(rootStoreModel, {
  name: storeName,
  devTools: (_CONFIG = CONFIG) === null || _CONFIG === void 0 ? void 0 : _CONFIG.devFeatures.reduxDevTools,
  middleware: middleware
});

if (typeof window !== 'undefined') {
  // inspired by https://github.com/rt2zz/redux-persist-crosstab/blob/master/index.js
  window.addEventListener('storage', function (e) {
    try {
      if (e.key && _startsWith(e.key, "[" + storeName + "]")) {
        if (e.oldValue === e.newValue) {
          // values are equals, skip
          return;
        }

        if (!e.newValue) {
          return;
        }

        var statePartial = JSON.parse(e.newValue);
        var simpleKey = e.key.substr(e.key.indexOf('@') + 1);
        logger.info("Persisted store property '" + simpleKey + "' updated in another tab/window. Rehydrating state...", {
          updatedData: statePartial.data,
          currentData: _get(rootStore.getState(), simpleKey)
        });

        _set(rootStore.getState(), simpleKey, statePartial.data);

        rootStore.reconfigure(rootStoreModel);
      }
    } catch (error) {
      logger.exception(error, "Rehydrating persisted state from external change failed!", {
        event: {
          key: e.key,
          newValue: e.newValue,
          oldValue: e.oldValue
        }
      });
    }
  }, false);
} // hot update


if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept(['@/common/store', '@/profile/store', '@/checkout/store', '@/artwork/store', '@/artwork/components/artwork-collections/withdraw-token/store', '@/exhibition/store'], function () {
      rootStore.reconfigure(rootStoreModel);
    });
  }
}

export { rootStore };