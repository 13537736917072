import _find from "lodash-es/find";
import { action } from 'easy-peasy';
var scrollModel = {
  pages: [],
  prevUri: null,
  currentPageStoredScrollPosition: null,
  setStoredScrollPosition: action(function (state, payload) {
    var currentPage = _find(state.pages, {
      uri: payload.uri
    });

    if (currentPage) {
      currentPage.storedScrollPosition = payload.scrollPosition;
    } else {
      var _state$pages;

      (_state$pages = state.pages) === null || _state$pages === void 0 ? void 0 : _state$pages.push({
        uri: payload.uri,
        storedScrollPosition: payload.scrollPosition
      });
    }
  }),
  getStoredScrollPosition: action(function (state, uri) {
    var currentPage = _find(state.pages, {
      uri: uri
    });

    if (currentPage) {
      state.currentPageStoredScrollPosition = currentPage === null || currentPage === void 0 ? void 0 : currentPage.storedScrollPosition;
    }
  }),
  setPrevUri: action(function (state, uri) {
    state.prevUri = uri;
  })
};
var scrollStore = {
  scroll: scrollModel
};
export { scrollStore };