import Axios from 'axios';
import { thunk } from 'easy-peasy';
var authenticatedAxiosModel = {
  getAxios: thunk(function (actions, payload, _ref) {
    var _CONFIG, _CONFIG$endpoints;

    var getStoreState = _ref.getStoreState,
        getStoreActions = _ref.getStoreActions;
    var axios = Axios.create({
      baseURL: (_CONFIG = CONFIG) === null || _CONFIG === void 0 ? void 0 : (_CONFIG$endpoints = _CONFIG.endpoints) === null || _CONFIG$endpoints === void 0 ? void 0 : _CONFIG$endpoints.authApi
    }); // NOTE: the following is more or less a 1:1 copy of the code from the legacy vue app

    axios.interceptors.request.use(function (request) {
      var accessToken = getStoreState().auth.accessToken;
      var _request$headers = request.headers,
          headers = _request$headers === void 0 ? {} : _request$headers;
      var authHeader = headers.Authorization;
      var hasSkipAuthHeader = !!headers['X-NOOW-SkipAuthorization'];

      if (hasSkipAuthHeader) {
        delete headers['X-NOOW-SkipAuthorization'];
      }

      if (accessToken && !authHeader && !hasSkipAuthHeader) {
        headers.Authorization = "Bearer " + accessToken;
      }

      request.headers = Object.assign({}, headers);
      return request;
    });
    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      var requestConfig = Object.assign({}, error.config);
      var responseStatus = error.response && error.response.status;

      if (responseStatus === 401) {
        getStoreActions().auth.logout();
        return Promise.reject(error);
      }

      if (responseStatus === 403) {
        if (requestConfig.headers['X-NOOW-RequestCopy']) {
          // We have already sent refresh token request, so we'll skip all future attempts
          // to avoid infinite token request loop
          return Promise.reject(error);
        }

        return new Promise(function (resolve, reject) {
          getStoreActions().auth.refreshAccessToken().then(function () {
            // Reset old Authorization header so that
            // request interceptor can do it's job setting it
            if (!requestConfig.headers) {
              requestConfig.headers = {};
            }

            requestConfig.headers['X-NOOW-RequestCopy'] = 'requestCopy'; // eslint-disable-next-line id-blacklist

            requestConfig.headers.Authorization = undefined;
            return axios(requestConfig).then(resolve).catch(function (resendError) {
              reject(error);
            });
          }).catch(function (refreshError) {
            reject(error);
          });
        });
      }

      return Promise.reject(error);
    });
    return axios;
  })
};
var authenticatedAxiosStore = {
  authenticatedAxios: authenticatedAxiosModel
};
export { authenticatedAxiosStore };