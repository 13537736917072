import { action, persist } from 'easy-peasy';
var DEFAULT_SEARCH_QUERY = {
  search: ''
};
var DEFAULT_SORT_QUERY = {};
var exhibitionsModel = {
  storedCurrentPage: 1,
  setStoredCurrentPage: action(function (state, payload) {
    state.storedCurrentPage = payload;
  }),
  storedSearchQuery: DEFAULT_SEARCH_QUERY,
  setStoredSearchQuery: action(function (state, payload) {
    state.storedSearchQuery = payload;
  })
};
var exhibitionsSortModel = {
  storedSortQuery: DEFAULT_SORT_QUERY,
  setStoredSortQuery: action(function (state, payload) {
    state.storedSortQuery = payload;
  })
};
var exhibitionsStore = {
  exhibitions: persist(exhibitionsModel, {
    storage: 'sessionStorage'
  }),
  exhibitionsSort: persist(exhibitionsSortModel, {
    storage: 'localStorage'
  })
};
export { exhibitionsStore };