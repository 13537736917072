import * as React from 'react';
import { OfflineSensor, OfflineStateProvider } from '@/common/hooks/offline-hook';
import { wrapRootElementApolloProvider } from '@/common/providers/apollo/wrap-root-element';
import { wrapRootElementEasyPeasyProvider } from '@/common/providers/easy-peasy/wrap-root-element';
import { wrapRootElementEmotionProvider } from '@/common/providers/emotion/wrap-root-element';
import { wrapRootElementWeb3Provider } from '@/common/providers/web3/wrap-root-element';
import { jsx as ___EmotionJSX } from "@emotion/core";

var wrapRootElement = function wrapRootElement(_ref) {
  var element = _ref.element;
  return wrapRootElementEasyPeasyProvider({
    element: wrapRootElementEmotionProvider({
      element: wrapRootElementWeb3Provider({
        element: wrapRootElementApolloProvider({
          element: ___EmotionJSX(OfflineStateProvider, null, ___EmotionJSX(OfflineSensor, null), element)
        })
      })
    })
  });
};

export { wrapRootElement };