import _reduce from "lodash-es/reduce";
import { BREAKPOINT_LABELS } from '@/common/utils/styling/breakpoint-labels';

var mediaquery = _reduce(BREAKPOINT_LABELS, function (acc, label) {
  acc[label] = function (_ref) {
    var _theme$breakpoints$la;

    var theme = _ref.theme;
    return "\n      @media (min-width: " + ((_theme$breakpoints$la = theme.breakpoints[label]) !== null && _theme$breakpoints$la !== void 0 ? _theme$breakpoints$la : 0) / 16 + "rem)\n    ";
  };

  return acc;
}, {});

mediaquery.ie11 = function () {
  return "@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)";
};

mediaquery.portait = function () {
  return "@media screen and (orientation: portrait)";
};

mediaquery.landscape = function () {
  return "@media screen and (orientation: landscape)";
};

export { mediaquery };