import _omitBy from "lodash-es/omitBy";
import _mapValues from "lodash-es/mapValues";
import _isObjectLike from "lodash-es/isObjectLike";
import { action, thunkOn } from 'easy-peasy';
import LogRocket from 'logrocket';
var logrocketModel = {
  identified: false,
  afterEnsureProfileLoaded: thunkOn(function (actions, storeActions) {
    return storeActions.profile.ensureProfileLoaded.successType;
  }, function (actions, target, _ref) {
    var getStoreState = _ref.getStoreState,
        getState = _ref.getState;

    if (!CONFIG.features.logrocket || getState().identified) {
      return;
    }

    var profile = getStoreState().profile.profile;

    if (profile) {
      var _profile$_id;

      // eslint-disable-next-line no-underscore-dangle
      LogRocket.identify("" + ((_profile$_id = profile._id) !== null && _profile$_id !== void 0 ? _profile$_id : profile.email), Object.assign({}, _mapValues(_omitBy(profile, function (value) {
        return _isObjectLike(value);
      }), function (value) {
        return "" + value;
      }), {
        name: profile.firstName + " " + profile.lastName,
        email: profile.email
      }));
      actions.setIdentified(true);
    }
  }),
  setIdentified: action(function (state, identified) {
    state.identified = identified;
  })
};
var logrocketStore = {
  logrocket: logrocketModel
};
export { logrocketStore };