import createStateContext from "react-use/esm/createStateContext";
import useEffectOnce from "react-use/esm/useEffectOnce";

var _createStateContext = createStateContext(false),
    useOfflineState = _createStateContext[0],
    OfflineStateProvider = _createStateContext[1];

var OfflineSensor = function OfflineSensor() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  var _useOfflineState = useOfflineState(),
      _ = _useOfflineState[0],
      setOffline = _useOfflineState[1];

  useEffectOnce(function () {
    var _navigator;

    if (typeof window === 'undefined') {
      return;
    }

    var onOnline = function onOnline() {
      return setOffline(false);
    };

    var onOffline = function onOffline() {
      return setOffline(true);
    };

    setOffline( // eslint-disable-next-line lodash/prefer-lodash-typecheck
    typeof ((_navigator = navigator) === null || _navigator === void 0 ? void 0 : _navigator.onLine) === 'boolean' ? !navigator.onLine : false);
    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);
    return function () {
      window.removeEventListener('online', onOnline);
      window.removeEventListener('offline', onOffline);
    };
  });
  return null;
};

function useOffline() {
  var _useOfflineState2 = useOfflineState(),
      offline = _useOfflineState2[0];

  return offline;
}

export { useOffline, OfflineSensor, OfflineStateProvider };