import { defaultTheme } from './default';
import { tokengateTheme } from './tokengate';
var theme;

if (CONFIG.theme === 'tokengate') {
  theme = tokengateTheme;
} else {
  theme = defaultTheme;
}

export { theme };