import { action, persist } from 'easy-peasy';
var DEFAULT_SEARCH_QUERY = {
  postTitle: null,
  postCategory: null,
  postDate: null
};
var postsModel = {
  storedSearchQuery: DEFAULT_SEARCH_QUERY,
  storedCurrentPage: 1,
  setStoredSearchQuery: action(function (state, payload) {
    state.storedSearchQuery = payload;
  }),
  setStoredCurrentPage: action(function (state, payload) {
    state.storedCurrentPage = payload;
  })
};
var postsStore = {
  posts: persist(postsModel, {
    storage: 'sessionStorage'
  })
};
export { postsStore };