import { action, persist } from 'easy-peasy';
var etherModel = {
  storedUsdPriceInEth: null,
  setUsdPriceInEth: action(function (state, payload) {
    state.storedUsdPriceInEth = payload;
  }),
  storedLastUpdateUsdPriceInEth: null,
  setLastUpdateUsdPriceInEth: action(function (state, payload) {
    state.storedLastUpdateUsdPriceInEth = payload;
  })
};
var etherStore = {
  ether: persist(etherModel, {
    storage: 'localStorage'
  })
};
export { etherStore };