import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _uniqueId from "lodash-es/uniqueId";
import _uniqBy from "lodash-es/uniqBy";
import _tail from "lodash-es/tail";
import _map from "lodash-es/map";
import _head from "lodash-es/head";
import _filter from "lodash-es/filter";
import { action, computed } from 'easy-peasy';
var notificationsModel = {
  queue: [],
  current: computed([function (state) {
    return state.queue;
  }], function (queue) {
    return _head(queue);
  }),
  queuedMessagesCount: computed([function (state) {
    return state.queue;
  }], function (queue) {
    return queue.length;
  }),
  show: action(function (state, item) {
    state.queue = _uniqBy([Object.assign({}, item, {
      id: _uniqueId('notification-')
    })].concat(_toConsumableArray(state.queue)), 'key');
  }),
  dismissCurrent: action(function (state) {
    state.queue = _tail(state.queue);
  }),
  clear: action(function (state) {
    var _state$queue;

    state.queue = _filter((_state$queue = state.queue) !== null && _state$queue !== void 0 ? _state$queue : [], {
      persist: true
    });
  }),
  clearAll: action(function (state) {
    state.queue = [];
  }),
  onPageNavigation: action(function (state) {
    var _state$queue2;

    state.queue = _map(_filter((_state$queue2 = state.queue) !== null && _state$queue2 !== void 0 ? _state$queue2 : [], {
      persist: true
    }), function (item) {
      return Object.assign({}, item, {
        persist: false
      });
    });
  })
};
var notificationsStore = {
  notifications: notificationsModel
};
export { notificationsStore };