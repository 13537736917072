import { action } from 'easy-peasy';
var collectionCarouselModel = {
  storedCarouselActiveSlide: 0,
  setCarouselActiveSlide: action(function (state, payload) {
    state.storedCarouselActiveSlide = payload;
  })
};
var collectionCarouselStore = {
  collectionCarousel: collectionCarouselModel
};
export { collectionCarouselStore };