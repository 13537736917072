import _debounce from "lodash-es/debounce";
import parseISO from "date-fns/parseISO";
import isBefore from "date-fns/isBefore";
import formatISO from "date-fns/formatISO";
import addHours from "date-fns/addHours";
import { action, computed, persist } from 'easy-peasy'; // todo config param?

var PENDING_CHECKOUT_EXPIRATION_HOURS = 3;

var debouncedNow = _debounce(function () {
  return new Date();
}, 5000, {
  leading: true
});

var CheckoutStatus;

(function (CheckoutStatus) {
  CheckoutStatus["New"] = "new";
  CheckoutStatus["LoginRequired"] = "loginRequired";
  CheckoutStatus["ProfileActivationRequired"] = "profileActivationRequired";
  CheckoutStatus["PaymentPending"] = "paymentPending";
  CheckoutStatus["Processing"] = "processing";
  CheckoutStatus["Completed"] = "completed";
})(CheckoutStatus || (CheckoutStatus = {}));

var checkoutModel = {
  storedCheckoutLoading: false,
  setCheckoutLoading: action(function (state, payload) {
    state.storedCheckoutLoading = payload;
  }),
  storedPendingCheckout: null,
  pendingCheckoutSinceDate: computed([function (state) {
    return state.storedPendingCheckout;
  }], function (pendingCheckout) {
    if (pendingCheckout) {
      return parseISO(pendingCheckout.pendingSince);
    }

    return null;
  }),
  hasPendingCheckout: computed([function (state) {
    return state.pendingCheckoutSinceDate;
  }, debouncedNow], function (pendingCheckoutSinceDate, now) {
    if (!pendingCheckoutSinceDate || !now) {
      return false;
    }

    return isBefore(now, addHours(pendingCheckoutSinceDate, PENDING_CHECKOUT_EXPIRATION_HOURS));
  }),
  pendingCheckout: computed([function (state) {
    return state.storedPendingCheckout;
  }, function (state) {
    return state.hasPendingCheckout;
  }], function (pendingCheckout, hasPendingCheckout) {
    if (!hasPendingCheckout) {
      return null;
    }

    return pendingCheckout;
  }),
  setPendingCheckout: action(function (state, _ref) {
    var artworkId = _ref.artworkId,
        artworkSlug = _ref.artworkSlug,
        status = _ref.status;
    state.storedPendingCheckout = {
      artworkId: artworkId,
      artworkSlug: artworkSlug,
      pendingSince: formatISO(new Date()),
      status: status
    };
  }),
  updatePendingCheckoutStatus: action(function (state, status) {
    if (state.storedPendingCheckout) {
      state.storedPendingCheckout.status = status;
    } // todo throw error?

  }),
  clearPendingCheckout: action(function (state) {
    state.storedPendingCheckout = null;
  }),
  termsAndConditionsChecked: false,
  setTermsAndConditionsChecked: action(function (state, value) {
    state.termsAndConditionsChecked = value;
  }),
  formIsInvalid: false,
  setFormIsInvalid: action(function (state, value) {
    state.formIsInvalid = value;
  }),
  checkoutProcess: false,
  setCheckoutProcess: action(function (state, value) {
    state.checkoutProcess = value;
  }),
  checkoutCryptoProcess: false,
  setCheckoutCryptoProcess: action(function (state, value) {
    state.checkoutCryptoProcess = value;
  }),
  checkoutComplete: false,
  setCheckoutComplete: action(function (state, value) {
    state.checkoutComplete = value;
  }),
  cryptoPaymentSelected: false,
  setCryptoPaymentSelected: action(function (state, value) {
    state.cryptoPaymentSelected = value;
  }),
  checkoutId: null,
  setCheckoutId: action(function (state, value) {
    state.checkoutId = value;
  }),
  checkoutCompleteType: null,
  setCheckoutCompleteType: action(function (state, value) {
    state.checkoutCompleteType = value;
  })
};
var checkoutStore = {
  checkout: persist(checkoutModel, {
    storage: 'localStorage',
    allow: ['storedPendingCheckout']
  })
};
export { checkoutStore, CheckoutStatus };