import _find from "lodash-es/find";
import { action, persist } from 'easy-peasy';
var WithdrawTokenStatus;

(function (WithdrawTokenStatus) {
  WithdrawTokenStatus["isWithdrawalAwaitingApproval"] = "isWithdrawalAwaitingApproval";
  WithdrawTokenStatus["withdrawalTransactionInProgress"] = "withdrawalTransactionInProgress";
  WithdrawTokenStatus["withdrawalTransactionConfirmed"] = "withdrawalTransactionConfirmed";
})(WithdrawTokenStatus || (WithdrawTokenStatus = {}));

var withdrawTokenModel = {
  tokens: [],
  setWithdrawTokenStatus: action(function (state, _ref) {
    var tokenId = _ref.tokenId,
        withdrawTokenStatus = _ref.withdrawTokenStatus;

    var currentToken = _find(state.tokens, {
      tokenId: tokenId
    });

    if (currentToken) {
      currentToken.withdrawTokenStatus = withdrawTokenStatus;
    } else {
      state.tokens.push({
        tokenId: tokenId,
        withdrawTokenStatus: withdrawTokenStatus
      });
    }
  })
};
var withdrawTokenStore = {
  withdrawToken: persist(withdrawTokenModel, {
    storage: 'localStorage'
  })
};
export { withdrawTokenStore, WithdrawTokenStatus };