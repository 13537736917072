import { ApolloProvider } from '@apollo/react-hooks';
import * as React from 'react';
import { client } from './client';
import { jsx as ___EmotionJSX } from "@emotion/core";

var wrapRootElementApolloProvider = function wrapRootElementApolloProvider(_ref) {
  var element = _ref.element;
  return ___EmotionJSX(ApolloProvider, {
    client: client
  }, element);
};

wrapRootElementApolloProvider.displayName = "wrapRootElementApolloProvider";
export { wrapRootElementApolloProvider };