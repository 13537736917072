import _mapValues from "lodash-es/mapValues";
import _map from "lodash-es/map";
import _isObjectLike from "lodash-es/isObjectLike";
import { isApolloError } from 'apollo-client';
import { printError } from 'graphql';
import LogRocket from 'logrocket';
var exceptionSymbol = String.fromCodePoint(0x1f525);
var errorSymbol = String.fromCodePoint(0x26d4);
var warningSymbol = String.fromCodePoint(0x26a0);
var infoSymbol = String.fromCodePoint(0x2139);
var debugSymbol = String.fromCodePoint(0x1f4c4);
var exceptionStyle = 'color: rgb(255,220,220); background: rgba(255,80,80, 0.7);  font-weight: bold; width: 100%; display: block;';
var errorStyle = 'color: rgb(255,30,20); font-weight: bold; width: 100%; display: block;';
var warningStyle = 'color: orange; font-weight: bold; width: 100%; display: block;';
var infoStyle = 'color: cyan; width: 100%; display: block;';
var debugStyle = 'color: rgb(110,110,110); font-weight: light; width: 100%; display: block;';

var getClassName = function getClassName(e) {
  return e.constructor.name || typeof e;
};

var encodeContext = function encodeContext(c) {
  return _mapValues(c, function (v) {
    return _isObjectLike(v) ? JSON.stringify(v) : v;
  });
};

var logger = {
  exception: function exception(e, message, context) {
    var _console$groupCollaps, _console, _console$groupEnd, _console2;

    if (CONFIG.features.logrocket) {
      var _e$stack, _Error$stack;

      LogRocket.captureException(e, {
        tags: {
          level: 'exception',
          exception: true,
          exceptionType: getClassName(e)
        },
        extra: Object.assign({
          message: message !== null && message !== void 0 ? message : e.message,
          errorSerialized: JSON.stringify(e),
          stack: (_e$stack = e.stack) !== null && _e$stack !== void 0 ? _e$stack : 'N/A',
          currentStack: (_Error$stack = new Error().stack) !== null && _Error$stack !== void 0 ? _Error$stack : 'N/A',
          exceptionName: e.name
        }, encodeContext(context))
      });
    }

    (_console$groupCollaps = (_console = console).groupCollapsed) === null || _console$groupCollaps === void 0 ? void 0 : _console$groupCollaps.call(_console, exceptionSymbol + " %c%s", exceptionStyle, message || e.message);
    console.error(message || e.message);
    console.error(e);

    if (context) {
      console.info(context);
    }

    (_console$groupEnd = (_console2 = console).groupEnd) === null || _console$groupEnd === void 0 ? void 0 : _console$groupEnd.call(_console2);
  },
  error: function error(message, context) {
    var _console$groupCollaps2, _console3, _console$groupEnd2, _console4;

    if (CONFIG.features.logrocket) {
      LogRocket.captureMessage(message, {
        tags: {
          level: 'error'
        },
        extra: encodeContext(context)
      });
    }

    (_console$groupCollaps2 = (_console3 = console).groupCollapsed) === null || _console$groupCollaps2 === void 0 ? void 0 : _console$groupCollaps2.call(_console3, errorSymbol + " %c%s", errorStyle, message);
    console.error(message);

    if (context) {
      console.info(context);
    }

    (_console$groupEnd2 = (_console4 = console).groupEnd) === null || _console$groupEnd2 === void 0 ? void 0 : _console$groupEnd2.call(_console4);
  },
  warn: function warn(message, context) {
    var _console$groupCollaps3, _console5, _console$groupEnd3, _console6;

    if (CONFIG.features.logrocket) {
      LogRocket.warn(message, context);
    }

    (_console$groupCollaps3 = (_console5 = console).groupCollapsed) === null || _console$groupCollaps3 === void 0 ? void 0 : _console$groupCollaps3.call(_console5, warningSymbol + " %c%s", warningStyle, message);
    console.warn(message);

    if (context) {
      console.info(context);
    }

    (_console$groupEnd3 = (_console6 = console).groupEnd) === null || _console$groupEnd3 === void 0 ? void 0 : _console$groupEnd3.call(_console6);
  },
  info: function info(message, context) {
    if (process.env.NODE_ENV !== 'production') {
      var _console$groupCollaps4, _console7, _console$groupEnd4, _console8;

      (_console$groupCollaps4 = (_console7 = console).groupCollapsed) === null || _console$groupCollaps4 === void 0 ? void 0 : _console$groupCollaps4.call(_console7, infoSymbol + " %c%s", infoStyle, message);
      console.info(message);

      if (context) {
        console.info(context);
      }

      (_console$groupEnd4 = (_console8 = console).groupEnd) === null || _console$groupEnd4 === void 0 ? void 0 : _console$groupEnd4.call(_console8);
    }

    if (CONFIG.features.logrocket) {
      LogRocket.info(message, context);
    }
  },
  debug: function debug(message, context) {
    var _console$groupCollaps5, _console9, _console$groupEnd5, _console10;

    if (process.env.NODE_ENV === 'production') {
      return;
    }

    (_console$groupCollaps5 = (_console9 = console).groupCollapsed) === null || _console$groupCollaps5 === void 0 ? void 0 : _console$groupCollaps5.call(_console9, debugSymbol + " %c%s", debugStyle, message);
    console.log(message);

    if (context) {
      console.log(context);
    }

    (_console$groupEnd5 = (_console10 = console).groupEnd) === null || _console$groupEnd5 === void 0 ? void 0 : _console$groupEnd5.call(_console10);
  },
  verbose: function verbose(message, context) {
    var _console$groupCollaps6, _console11, _console$groupEnd6, _console12;

    if (process.env.NODE_ENV === 'production') {
      return;
    }

    (_console$groupCollaps6 = (_console11 = console).groupCollapsed) === null || _console$groupCollaps6 === void 0 ? void 0 : _console$groupCollaps6.call(_console11, "%c%s", debugStyle, message);
    console.log(message);

    if (context) {
      console.log(context);
    }

    (_console$groupEnd6 = (_console12 = console).groupEnd) === null || _console$groupEnd6 === void 0 ? void 0 : _console$groupEnd6.call(_console12);
  }
};

var extractAxiosErrorContext = function extractAxiosErrorContext(e) {
  var _e$response;

  if (!e.isAxiosError) {
    return {};
  }

  return {
    requestUrl: e.config.url,
    reposneStatus: (_e$response = e.response) === null || _e$response === void 0 ? void 0 : _e$response.status,
    response: JSON.stringify(e.response)
  };
};

var extractGraphQLErrorContext = function extractGraphQLErrorContext(e) {
  var _e$extensions, _e$extensions$excepti;

  var context = {};

  if ((_e$extensions = e.extensions) !== null && _e$extensions !== void 0 && (_e$extensions$excepti = _e$extensions.exception) !== null && _e$extensions$excepti !== void 0 && _e$extensions$excepti.logCode) {
    context.logCode = e.extensions.exception.logCode;
    context.graphqlException = JSON.stringify(e.extensions.exception);
  }

  return Object.assign({
    asString: printError(e)
  }, context);
};

var extractApolloErrorContext = function extractApolloErrorContext(e) {
  if (!isApolloError(e)) {
    return {};
  }

  return {
    extraInfo: JSON.stringify(e.extraInfo),
    graphQLErrors: JSON.stringify(_map(e.graphQLErrors, extractGraphQLErrorContext))
  };
};

export { logger, extractAxiosErrorContext, extractApolloErrorContext, extractGraphQLErrorContext };