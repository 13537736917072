/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { throttle } from 'lodash-es';

export { wrapRootElement } from './src/wrap-root-element';
import React from 'react';
import { rootStore } from 'common/providers/easy-peasy/root-store';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { enableAllPlugins } from 'immer';

enableAllPlugins();

const setViewportProperty = () => {
  // used by postcss-viewport-height-correction
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px');
};

export const onInitialClientRender = () => {
  window.addEventListener('resize', throttle(setViewportProperty, 100));
  setViewportProperty();
};

// export const onClientEntry = () => {
//   if (process.env.NODE_ENV !== 'production') {
//     const whyDidYouRender = require('@welldone-software/why-did-you-render');
//     whyDidYouRender(React, {
//       trackAllPureComponents: true,
//       trackHooks: true,
//       logOnDifferentValues: true,
//       logOwnerReasons: true,
//     });
//   }
// };

export const onServiceWorkerUpdateReady = () => {
  // window.location.reload();
};

const scrollTo = (id) => () => {
  const el = document.querySelector(id);
  if (el) {
    const top = window.scrollY + el.getBoundingClientRect().top - 100;
    return window.scrollTo({ top, behavior: 'smooth' });
  }
  return false;
};

export const onRouteUpdate = ({ prevLocation, location }) => {
  // clear notifications
  rootStore.getActions().notifications.onPageNavigation();
  rootStore.getActions().pageSettings.onPageNavigation();

  // store prev url
  rootStore
    .getActions()
    .location.onPageNavigation(prevLocation?.pathname.substring(3));

  // hash scrolling
  if (location?.hash) {
    window.setTimeout(scrollTo(location?.hash), 10);
  }
};

export const onPreRouteUpdate = ({ location }) => {
  if (CONFIG.maintenanceMode) {
    if (!location.pathname.includes('/maintenance/')) {
      window.location.assign('/en/maintenance/');
      throw new Error('Abort redirect');
    }
  }
};

export const onClientEntry = () => {
  if (CONFIG.features.logrocket) {
    LogRocket.init(CONFIG.logrocket.appId, {
      release: `${APP_DISPLAYABLE_VERSION}-${APP_COMMIT_HASH}`,
      console: {
        isEnabled: {
          log: false,
          info: true,
          debug: true,
          warn: true,
          error: true,
        },
      },
    });
    setupLogRocketReact(LogRocket);
  }
};

export const registerServiceWorker = () => true;
