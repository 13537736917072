import { action } from 'easy-peasy';
var pageSettingsModel = {
  stickHeaderEnabled: true,
  mobileMenuOpen: false,
  disableStickyHeader: action(function (state) {
    state.stickHeaderEnabled = false;
  }),
  setMobileMenuOpen: action(function (state, mobileMenuOpen) {
    state.mobileMenuOpen = mobileMenuOpen;
  }),
  onPageNavigation: action(function (state) {
    state.stickHeaderEnabled = true;
    state.mobileMenuOpen = false;
  })
};
var pageSettingsStore = {
  pageSettings: pageSettingsModel
};
export { pageSettingsStore };