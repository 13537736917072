import { action } from 'easy-peasy';
var locationModel = {
  prevLocation: null,
  onPageNavigation: action(function (state, location) {
    state.prevLocation = location;
  })
};
var locationStore = {
  location: locationModel
};
export { locationStore };