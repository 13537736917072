import * as React from 'react';
import { ThemeProvider } from 'emotion-theming';
import { theme } from '@/themes';
import { jsx as ___EmotionJSX } from "@emotion/core";

var wrapRootElementEmotionProvider = function wrapRootElementEmotionProvider(_ref) {
  var element = _ref.element;
  return ___EmotionJSX(ThemeProvider, {
    theme: theme
  }, element);
};

wrapRootElementEmotionProvider.displayName = "wrapRootElementEmotionProvider";
export { wrapRootElementEmotionProvider };