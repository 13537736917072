import * as React from 'react';
import { StoreProvider } from 'easy-peasy';
import { rootStore } from '@/common/providers/easy-peasy/root-store';
import { jsx as ___EmotionJSX } from "@emotion/core";

var wrapRootElementEasyPeasyProvider = function wrapRootElementEasyPeasyProvider(_ref) {
  var element = _ref.element;
  return ___EmotionJSX(StoreProvider, {
    store: rootStore
  }, element);
};

wrapRootElementEasyPeasyProvider.displayName = "wrapRootElementEasyPeasyProvider";
export { wrapRootElementEasyPeasyProvider };