import { action, persist } from 'easy-peasy';
var volumeModel = {
  storedVideoMuted: false,
  setVideoMuted: action(function (state, payload) {
    state.storedVideoMuted = payload;
  })
};
var volumeStore = {
  volume: persist(volumeModel, {
    storage: 'localStorage'
  })
};
export { volumeStore };