import { action, persist } from 'easy-peasy';
var DEFAULT_SEARCH_QUERY = {
  search: '',
  category: null,
  editionSize: null,
  purchasePrice: null
};
var DEFAULT_SORT_QUERY = {};
var artworksModel = {
  storedSearchQuery: DEFAULT_SEARCH_QUERY,
  storedCurrentPage: 1,
  setStoredSearchQuery: action(function (state, payload) {
    var storedSearchQuery = state.storedSearchQuery;
    state.storedSearchQuery = Object.assign({}, storedSearchQuery, payload);
  }),
  setStoredCurrentPage: action(function (state, payload) {
    state.storedCurrentPage = payload;
  })
};
var artworksSortModel = {
  storedSortQuery: DEFAULT_SORT_QUERY,
  storedReducedFilterSelected: '',
  setStoredSortQuery: action(function (state, payload) {
    state.storedSortQuery = payload;
  }),
  setStoredReducedFilterSelected: action(function (state, payload) {
    state.storedReducedFilterSelected = payload;
  })
};
var artworksStore = {
  artworks: persist(artworksModel, {
    storage: 'sessionStorage'
  }),
  artworksSort: persist(artworksSortModel, {
    storage: 'localStorage'
  })
};
export { artworksStore };